<template>
    <div class="membership-img-container">
        <div class="img-wrapper">
            <img
                v-if="selectedMembership === 'mf_3month' && me.gender == 1"
                :src="require('@/assets/images/membership/marry-fit-female-7.png')"
                alt=""
            />
            <img
                v-else-if="selectedMembership !== 'mf_3month'"
                :src="require('@/assets/images/membership/marry-fit-plus-7.png')"
                alt=""
            />
        </div>
    </div>
</template>

<script>
export default {
    name: 'MembershipDetail7',
    props: ['selectedMembership'],
    computed: {
        me() {
            return this.$store.getters.me
        },
        products() {
            return this.$store.getters.products
        },
    },
}
</script>

<style scoped></style>
